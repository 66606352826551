import { datadogRum } from '@datadog/browser-rum';
import defaultConfig from 'constants/defaultConfig';
import { getDevice, isSSRUserAgent } from 'utils';

export var dataDogRum = {
  isMonitoringStarted: false,
  isSessionReplayRecordingStarted: false,
  user: null,

  init: ({ user, sessionSampleRate = 0, sessionReplaySampleRate = 0 }) => {
    if (
      !defaultConfig.dataDogRumAppId ||
      !defaultConfig.dataDogRumClientToken ||
      !navigator.cookieEnabled ||
      isSSRUserAgent()
    ) {
      return;
    }
    dataDogRum.user = user;
    datadogRum.init({
      applicationId: defaultConfig.dataDogRumAppId,
      clientToken: defaultConfig.dataDogRumClientToken,
      site: 'datadoghq.com',
      service: `web-${defaultConfig.env}`,
      env: defaultConfig.env,
      version: process.env.REACT_APP_VERSION || defaultConfig.version,
      sessionSampleRate,
      sessionReplaySampleRate, // Set to 0 to disable replay recording.
      trackUserInteractions: true,
      startSessionReplayRecordingManually: true, // always manually start rather than on init
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    dataDogRum.isMonitoringStarted = true;

    dataDogRum.updateUserDetails(user);
  },

  toggleSessionReplayRecording: (toggle) => {
    if (dataDogRum.isMonitoringStarted) {
      if (toggle) {
        if (!dataDogRum.isSessionReplayRecordingStarted) {
          datadogRum.startSessionReplayRecording();
          dataDogRum.isSessionReplayRecordingStarted = true;
        }
      } else {
        if (dataDogRum.isSessionReplayRecordingStarted) {
          datadogRum.stopSessionReplayRecording();
          dataDogRum.isSessionReplayRecordingStarted = false;
        }
      }
    }
  },

  onUserLoggedIn: (user) => {
    dataDogRum.updateUserDetails(user);
    dataDogRum.toggleSessionReplayRecording(true);
  },

  onUserLoggedOut: (user, enableSessionReplayLoggedOutUsers) => {
    dataDogRum.updateUserDetails(user);
    // OSN-2888 - we need to enable session replay for logged out users as well
    dataDogRum.toggleSessionReplayRecording(enableSessionReplayLoggedOutUsers);
  },

  updateUserDetails: (user) => {
    dataDogRum.user = user;
    const { deviceId } = getDevice();

    if (dataDogRum.isMonitoringStarted) {
      datadogRum.setUserProperty('id', user.userId);
      datadogRum.setUserProperty('type', user.type);
      datadogRum.setUserProperty('device', deviceId);
      datadogRum.setUserProperty('loggedIn', user.loggedIn);
    }
  },
};
